export interface INewUser {
  email: string;
  name: string;
}

export interface IUser {
  user_id: string;
  email: string;
  tenant_name: string;
  wallet_id?: string;
  tenant_id?: string;
  tenant_api_key?: string;
  connection_id?: string;
}

export interface IUserWallet {
  user_id?: string;
  email: string;
  name?: string;
  tenant_name?: string;
  reservation_id?: string | null;
  reservation_pwd?: string | null;
  tenant_id?: string;
  tenant_api_key?: string;
  wallet_id?: string;
  connection_id?: string | null;
  invitation_key?: string | null;
  firstLogin?: boolean;
}

export class User {
  user_id: string;
  email: string;
  tenant_name: string;
  wallet_id: string;
  tenant_id: string;
  tenant_api_key: string;
  connection_id: string;

  constructor(data: IUser) {
    this.user_id = data.user_id;
    this.email = data.email;
    this.tenant_name = data.tenant_name;
    this.wallet_id = data.wallet_id || '';
    this.tenant_id = data.tenant_id || '';
    this.tenant_api_key = data.tenant_api_key || '';
    this.connection_id = data.connection_id || '';
  }
}
