import { StyleSheet, Text, View } from 'react-native';
import { mdiLogin, mdiLogout } from '@mdi/js';
import { useState } from 'react';
import HeaderButton from './HeaderButton';
import LogoutModal from '../../modal/LogoutModal';
import { useAuthStateContext } from '../../contexts';

type HeaderProps = {
  title: string;
};

const Header: React.FC<HeaderProps> = (props) => {
  const authStateContext = useAuthStateContext();
  const user = authStateContext.auth.user;

  const [modalVisible, setModalVisible] = useState(false);

  const handleLogout = () => {
    setModalVisible(true);
  };

  return (
    <View style={styles.header}>
      <LogoutModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
      <Text style={styles.headerTextStyle}>{props.title}</Text>
      {user ? (
        <>
          <View style={styles.headerRightStyle}>
            <HeaderButton onPress={handleLogout} icon={mdiLogout}></HeaderButton>
          </View>
        </>
      ) : (
        <View style={styles.headerRightStyle}>
          <HeaderButton
            onPress={authStateContext.auth.signinRedirect}
            icon={mdiLogin}></HeaderButton>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: 72,
    paddingVertical: 27,
    paddingHorizontal: 24,
    backgroundColor: '#F7F8FA'
  },
  headerTextStyle: {
    display: 'flex',
    flex: 1,
    color: '#000000',
    fontSize: 14,
    fontWeight: 700
  },
  headerRightStyle: {
    width: 32
  }
});

export default Header;
