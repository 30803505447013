import React from 'react';
import { Pressable, Text, TextStyle, ViewStyle } from 'react-native';
import BackIcon from '../../icons/BackIcon';

export interface GenericBackProps {
  onPress: () => void;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
}

const GenericBack: React.FC<GenericBackProps> = (props) => {
  return (
    <Pressable style={props.buttonStyle} onPress={props.onPress}>
      <BackIcon />
      <Text style={props.textStyle}>Back</Text>
    </Pressable>
  );
};

export default GenericBack;
