import React, { useState, ReactNode } from 'react';
import { DialogContext } from '.';
import SendUserInviteModal from '../modal/SendUserInviteModal';
import SendUserCredModal from '../modal/SendUserCredModal';
import { IUser } from '../types/user';
import { IReservation } from '../types/reservation';

/**
 * Provides dialog context.
 *
 * @param {*} { children }
 * @return {*}
 */
export const DialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [invitation, setInvitation] = useState<IReservation | undefined>(undefined);
  const [user, setUser] = useState<IUser | undefined>(undefined);

  const [sendUserInviteModalOpen, setSendUserInviteModalOpen] = useState(false);
  const [sendUserCredModalOpen, setSendUserCredModalOpen] = useState(false);

  const sendUserInviteDialog = (reservation: IReservation) => {
    setInvitation(reservation);
    setSendUserInviteModalOpen(true);
  };

  const sendUserCredDialog = (user: IUser) => {
    setUser(user);
    setSendUserCredModalOpen(true);
  };

  return (
    <DialogContext.Provider
      value={{
        sendUserInviteDialog,
        sendUserCredDialog
      }}>
      <SendUserInviteModal
        isVisible={sendUserInviteModalOpen}
        onClose={() => setSendUserInviteModalOpen(false)}
        reservation={invitation as IReservation}
      />
      <SendUserCredModal
        isVisible={sendUserCredModalOpen}
        onClose={() => setSendUserCredModalOpen(false)}
        user={user as IUser}
      />
      {children}
    </DialogContext.Provider>
  );
};
