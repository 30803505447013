import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import GenericButton from '../../../components/molecules/buttons/GenericButton';
import EmptyCredentialIcon from '../../../components/icons/EmptyCredentialIcon';
import { IUser } from '../../../types/user';

interface ICredentialListProps {
  item: IUser;
  credentials: any[];
}

/**
 * CredentialList component
 *
 * @param {*} { item, credentials }
 * @return {*}
 */
const CredentialList: React.FC<ICredentialListProps> = ({ item, credentials }) => {
  if (credentials.length === 0) {
    return (
      <View style={styles.emptyCredentialContainer}>
        <EmptyCredentialIcon />
        <Text style={styles.noCredentialHeaderText}>No credentials</Text>
        <Text
          style={styles.secondaryText}>{`${item?.tenant_name} doesn't have any credentials`}</Text>
        <GenericButton
          title={'Issue Credential'}
          onPress={function (): void {
            throw new Error('Function not implemented.');
          }}
          buttonStyle={styles.button}
          textStyle={styles.buttonText}
          isPrimary={true}
        />
      </View>
    );
  }

  return (
    <>
      {credentials.map((credential) => (
        <>
          <View style={styles.credentialContainer} key={credential.credential_id}>
            <View>
              <Text style={[styles.primaryText, { marginLeft: 24 }]}>{credential.name}</Text>
              <Text style={[styles.secondaryText, { marginLeft: 24 }]}>Credential Details:</Text>
            </View>
            <GenericButton
              title={'Revoke'}
              onPress={function (): void {
                throw new Error('Function not implemented.');
              }}
              buttonStyle={styles.revokeButton}
              textStyle={styles.buttonText}
              isPrimary={true}
            />
          </View>

          {credential.attributes.map((attribute: any) => {
            return (
              <View style={{ flexDirection: 'row' }} key={attribute.name}>
                <Text style={[styles.secondaryText, { marginLeft: 48 }]}>
                  {`${attribute.name}: `}
                </Text>
                <Text style={styles.secondaryText}>{attribute.value}</Text>
              </View>
            );
          })}
        </>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  emptyCredentialContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  credentialContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  button: {
    width: 141,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  },
  primaryText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#000000'
  },
  secondaryText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000000'
  },
  noCredentialHeaderText: {
    fontSize: 24,
    fontWeight: 700,
    color: '#000'
  },
  revokeButton: {
    width: 83,
    height: 41,
    borderRadius: 6,
    margin: 4,
    marginRight: 24
  }
});

export default CredentialList;
