import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { useTenantContext } from '../../../contexts';
import DeleteConfirmModal from '../../../modal/DeleteConfirmModal';
import Layout from '../../../components/layout/Layout';
import GenericButton from '../../../components/molecules/buttons/GenericButton';
import CredentialList from './CredentialList';
import GenericBack from '../../../components/molecules/buttons/GenericBack';
import { IUser } from '../../../types/user';

interface IWalletUserDetailsProps {
  item: IUser;
  setVisible: (value: boolean) => void;
  visible: boolean;
}

const WalletUserDetails: React.FC<IWalletUserDetailsProps> = ({ item, setVisible, visible }) => {
  const { fetchCredentials } = useTenantContext();

  const [credentials, setCredentials] = useState<any[]>([]);
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);

  useEffect(() => {
    const getCredentials = async () => {
      if (item && item.connection_id) setCredentials(await fetchCredentials(item.connection_id));
    };
    if (visible) {
      getCredentials();
    }
  }, [item, fetchCredentials, visible]);

  const openDeleteUserModal = () => {
    setDeleteUserModalVisible(true);
  };

  const closeDeleteUserModal = () => {
    setDeleteUserModalVisible(false);
  };

  const deleteUser = async () => {
    try {
      // Delete user with wallet
      throw new Error('Function not implemented.');
    } catch (error) {
      console.error(error);
    }
  };

  const issueCredential = async () => {
    try {
      // Issue credential
      throw new Error('Function not implemented.');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout title="Issuer Application">
      <DeleteConfirmModal
        isVisible={deleteUserModalVisible}
        onClose={closeDeleteUserModal}
        onConfirm={deleteUser}
      />
      <View style={styles.Container}>
        <GenericBack onPress={() => setVisible(false)} buttonStyle={styles.backButton} />
        <View style={styles.inviteContainer}>
          <View>
            <Text style={styles.nameHeader}>{item?.tenant_name}</Text>
            <Text style={styles.secondaryText}>{item?.email}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <GenericButton
              title={'Issue Credential'}
              onPress={issueCredential}
              buttonStyle={styles.button}
              textStyle={styles.buttonText}
              isPrimary={true}
            />
            <GenericButton
              title={'Delete Wallet'}
              onPress={openDeleteUserModal}
              buttonStyle={styles.button}
              textStyle={styles.buttonText}
              isPrimary={true}
            />
          </View>
        </View>
        <View>
          <Text style={styles.secondaryText}>{`Tenant ID: ${item.tenant_id}`}</Text>
          <Text style={styles.secondaryText}>{`Wallet ID: ${item?.wallet_id}`}</Text>
        </View>
        <>
          <View>
            <Text style={styles.credentialText}>{`Credentials (${credentials.length})`}</Text>
          </View>
          <CredentialList item={item} credentials={credentials} />
        </>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    width: '100%'
  },
  emptyCredentialContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  credentialContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: 24,
    borderTopColor: '#000000',
    borderTopWidth: 1,
    marginTop: 21
  },
  button: {
    width: 141,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  deleteReservationButton: {
    width: 156,
    height: 41,
    borderRadius: 6,
    margin: 4
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  },
  inviteContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  nameHeader: {
    fontSize: 32,
    fontWeight: 700,
    color: '#000000'
  },
  primaryText: {
    fontSize: 16,
    fontWeight: 700,
    color: '#000000'
  },
  secondaryText: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000000'
  },
  credentialText: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000',
    marginTop: 21
  },
  noCredentialHeaderText: {
    fontSize: 24,
    fontWeight: 700,
    color: '#000'
  },
  revokeButton: {
    width: 83,
    height: 41,
    borderRadius: 6,
    margin: 4,
    marginRight: 24
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 2,
    marginBottom: 16
  }
});

export default WalletUserDetails;
