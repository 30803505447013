import React, { useEffect, useState } from 'react';
import { ConfigContext, IConfig } from '.';
import { IConfigResponse } from '../types/config';
import axios from 'axios';
import { ensureProtocol } from '../utils/Utils';

/**
 * Return the app config based on the public config endpoint.
 *
 * @return {*}  {Promise<IConfig>}
 */
const getConfig = async (): Promise<IConfig> => {
  /*
    NOTE: This is a placeholder for when we have a real deployed config to fetch.
    This will be a call to the deployed app's `/config` endpoint.
  */

  try {
    const response = await axios.get('/config/config.json');
    const config: IConfigResponse = response.data as IConfigResponse;

    const configReturn = {
      KEYCLOAK_URL: config.KEYCLOAK_URL,
      KEYCLOAK_REALM: config.KEYCLOAK_REALM,
      KEYCLOAK_CLIENT_ID: config.KEYCLOAK_CLIENT_ID,
      WALLET_HOST: ensureProtocol(config.WALLET_HOST)
    };

    return configReturn;
  } catch (error) {
    console.error('Error fetching config:', error);
    return {
      KEYCLOAK_URL: '',
      KEYCLOAK_REALM: '',
      KEYCLOAK_CLIENT_ID: '',
      WALLET_HOST: ''
    };
  }
};

/**
 * Provides environment variables.
 *
 * This will fetch env vars from either `process.env` if running with NODE_ENV=development or from the deployed app.
 *
 * @param {*} props
 * @return {*}
 */
const ConfigContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [config, setConfig] = useState<IConfig>();

  useEffect(() => {
    const loadConfig = async () => {
      const deployedConfig = await getConfig();
      setConfig(deployedConfig);
    };

    if (!config) {
      loadConfig();
    }
  }, [config]);

  return <ConfigContext.Provider value={config}>{props.children}</ConfigContext.Provider>;
};

export default ConfigContextProvider;
