import Tenant from '../types/tenant';
import { INewUser, IUser, User } from '../types/user';
import { UserReservation } from '../types/reservation';
import { CredentialExchangeRecord } from '../types/credentials';

/**
 * AgentService class
 *
 * @export
 * @class AgentService
 */
export default class AgentService {
  constructor(private token: string) {
    this.token = token;
  }
  /**
   * Fetch tenant
   *
   * @return {*}  {Promise<Tenant>}
   * @memberof AgentService
   */
  async fetchTenant(): Promise<Tenant> {
    const res = await fetch('/api/issuer/admin/tenant', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to fetch tenant');
    }

    const body = await res.json();

    const tenant = new Tenant(body);
    return tenant;
  }

  /**
   * Fetch invitation
   *
   * @param {INewUser} data
   * @return {*}  {Promise<UserReservation>}
   * @memberof AgentService
   */
  async fetchInvitation(data: INewUser): Promise<UserReservation> {
    const res = await fetch('/api/issuer/admin/register', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      },
      body: JSON.stringify({
        email: data.email.toLowerCase(),
        name: data.name
      } as any)
    });

    if (!res.ok) {
      throw new Error('Failed to fetch invitation');
    }

    const body = await res.json();

    const reservation = new UserReservation(body);

    return reservation;
  }

  /**
   * Fetch reservations
   *
   * @return {*}  {Promise<UserReservation[]>}
   * @memberof AgentService
   */
  async fetchReservations(): Promise<UserReservation[]> {
    const res = await fetch('/api/issuer/admin/reservations', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to fetch reservations');
    }

    const body = await res.json();

    const reservations = body.map((reservation: any) => new UserReservation(reservation));

    return reservations;
  }

  /**
   * Fetch users
   *
   * @return {*}  {Promise<IUser[]>}
   * @memberof AgentService
   */
  async fetchUsers(): Promise<User[]> {
    const res = await fetch('/api/issuer/admin/users', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to fetch users');
    }

    const body = await res.json();

    const users = body.map((user: any) => new User(user));

    return users;
  }

  /**
   * Fetch reservation
   *
   * @param {string} reservation_id
   * @return {*}  {Promise<UserReservation>}
   * @memberof AgentService
   */
  async fetchReservation(reservation_id: string): Promise<UserReservation> {
    const res = await fetch(`/api/issuer/user/${reservation_id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to fetch reservation');
    }

    const body = await res.json();

    const reservation = new UserReservation(body);

    return reservation;
  }

  /**
   * Create wallet
   *
   * @return {*}  {Promise<boolean>}
   * @memberof AgentService
   */
  async createWallet(): Promise<boolean> {
    const res = await fetch('/api/issuer/user/onboard', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed to create wallet');
    }

    const body: boolean = await res.json();

    return body === true;
  }

  /**
   * Send credential
   *
   * @param {string} email
   * @param {string} reservationId
   * @param {string} walletId
   * @return {*}  {Promise<boolean>}
   * @memberof AgentService
   */
  async sendCredential(user: IUser): Promise<boolean> {
    const res = await fetch('/api/issuer/admin/send-credential', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      },
      body: JSON.stringify({
        email: user.email,
        tenant_id: user.tenant_id,
        wallet_id: user.wallet_id
      })
    });

    if (!res.ok) {
      throw new Error('Failed to send credential');
    }

    const body: boolean = await res.json();

    return body === true;
  }

  /**
   * Send email
   *
   * @param {string} email
   * @return {*}  {Promise<void>}
   * @memberof AgentService
   */
  async sendEmail(email: string): Promise<void> {
    const res = await fetch('/api/issuer/admin/send-email', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      },
      body: JSON.stringify({
        email: email
      })
    });

    if (!res.ok) {
      throw new Error('Failed to send email');
    }

    return;
  }

  /**
   * Fetch credentials
   *
   * @param {string} email
   * @return {*}  {Promise<CredentialExchangeRecord[]>}
   * @memberof AgentService
   */
  async fetchCredentials(connection_id: string): Promise<CredentialExchangeRecord[]> {
    const res = await fetch('/api/issuer/admin/fetch-credentials', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      },
      body: JSON.stringify({
        connection_id: connection_id
      })
    });

    if (!res.ok) {
      console.log(res);
      throw new Error('Failed to fetch credentials');
    }

    const body: CredentialExchangeRecord[] = await res.json();

    return body;
  }

  /**
   * Delete user reservation
   *
   * @param {string} reservationId
   * @return {*}  {Promise<boolean>}
   * @memberof AgentService
   */
  async deleteUserReservation(reservationId: string): Promise<boolean> {
    const res = await fetch('/api/issuer/admin/delete-reservation', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`
      },
      body: JSON.stringify({
        reservation_id: reservationId
      })
    });

    if (!res.ok) {
      throw new Error('Failed to delete reservation');
    }

    const body: boolean = await res.json();

    return body;
  }
}
