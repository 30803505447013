import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import EmptyTenantIcon from '../../../components/icons/EmptyTenantIcon';
import OnboardUserButton from '../../../components/molecules/buttons/OnboardUserButton';

const EmptyTenant: React.FC = () => {
  return (
    <View style={styles.emptyContainer}>
      <EmptyTenantIcon />
      <Text style={styles.emptyText}>No Wallets</Text>
      <Text style={styles.emptySubText}>You don't have any wallet users at the moment.</Text>
      <OnboardUserButton title="+ Invite User" />
    </View>
  );
};

const styles = StyleSheet.create({
  emptyText: {
    fontSize: 24,
    fontWeight: 700
  },
  emptySubText: {
    fontSize: 16,
    fontWeight: 400
  },
  emptyContainer: {
    margin: 0,
    height: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15
  }
});

export default EmptyTenant;
