import React from 'react';
import WalletUserItem from './wallet-components/WalletUserItem';
import ReservationUserItem from './reservation-components/ReservationUserItem';
import { IUser } from '../../types/user';
import { IReservation } from '../../types/reservation';

interface IUserItemProps {
  item: IUser & IReservation;
}

const UserItem: React.FC<IUserItemProps> = ({ item }) => {
  return (
    <>
      {item.reservation_id ? <ReservationUserItem item={item} /> : <WalletUserItem item={item} />}
    </>
  );
};

export default UserItem;
