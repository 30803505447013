import React, { useCallback, useState } from 'react';
import Tenant from '../types/tenant';
import { TenantContext, TenantContextProviderProps } from '.';
import { useAgentService } from '../services';
import { IUser } from '../types/user';
import { IReservation } from '../types/reservation';

/**
 * TenantContextProvider
 *
 * @param {*} { children }
 * @return {*}
 */
const TenantContextProvider: React.FC<TenantContextProviderProps> = ({ children }) => {
  const agentService = useAgentService();

  const [tenant, setTenant] = useState<Tenant | undefined>(undefined);
  const [users, setUsers] = useState<IUser[]>([]);
  const [reservations, setReservations] = useState<IReservation[]>([]);

  const fetchTenant = useCallback(async () => {
    const tenantResponse = await agentService.fetchTenant();
    if (tenantResponse) {
      setTenant(tenantResponse);
    }
    return tenantResponse;
  }, [agentService]);

  const fetchUsers = useCallback(async () => {
    const usersResponse = await agentService.fetchUsers();
    if (usersResponse) {
      setUsers(usersResponse);
    }

    return usersResponse;
  }, [agentService]);

  const fetchReservation = useCallback(
    async (reservationId: string) => {
      const reservation = await agentService.fetchReservation(reservationId);
      return reservation;
    },
    [agentService]
  );

  const fetchReservations = useCallback(async () => {
    const reservationsResponse = await agentService.fetchReservations();
    setReservations(reservationsResponse);

    return reservationsResponse;
  }, [agentService]);

  const fetchCredentials = useCallback(
    async (connection_id: string) => {
      const credentials = await agentService.fetchCredentials(connection_id);
      return credentials;
    },
    [agentService]
  );

  const refresh = useCallback(async () => {
    fetchTenant();
    fetchUsers();
    fetchReservations();
  }, [fetchTenant, fetchUsers, fetchReservations]);

  return (
    <TenantContext.Provider
      value={{
        tenant,
        reservations,
        users,
        fetchTenant,
        fetchUsers,
        fetchReservation,
        fetchReservations,
        fetchCredentials,
        refresh
      }}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContextProvider;
