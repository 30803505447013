import React from 'react';

const EmptyCredentialIcon: React.FC = () => {
  return (
    <svg width="96" height="97" viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 34.5H60.96M24 66.5H29.16"
        stroke="#ADB9E1"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44 66.5H58"
        stroke="#ADB9E1"
        strokeWidth="5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.92 82.5001H70.24C84.48 82.5001 88 78.9801 88 64.9401V28.0601"
        stroke="#ADB9E1"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.96 15.5C77.48 14.78 74.28 14.5 70.24 14.5H25.76C11.56 14.5 8 18.02 8 32.06V64.9C8 74.26 9.56 78.94 14.84 81.02M88 8.5L8 88.5"
        stroke="#1C274C"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyCredentialIcon;
