import React from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { useTenantContext } from '../../contexts';
import UserItem from './UserItem';
import EmptyTenant from './components/EmptyTenant';
import OnboardUserButton from '../../components/molecules/buttons/OnboardUserButton';
import { IUser } from '../../types/user';
import { IReservation } from '../../types/reservation';

/**
 * UsersTable component
 *
 * @return {*}
 */
const UsersTable: React.FC = () => {
  const tenantContext = useTenantContext();

  const renderItem = ({ item }: { item: IUser & IReservation }) => <UserItem item={item} />;

  return (
    <View style={styles.Container}>
      <View style={styles.inviteContainer}>
        <Text style={styles.headerText}>Wallets</Text>
        <OnboardUserButton title="+ Invite User" />
      </View>
      {tenantContext.users?.length === 0 && tenantContext.reservations?.length === 0 ? (
        <EmptyTenant />
      ) : (
        <FlatList
          data={[...(tenantContext.users || []), ...(tenantContext.reservations || [])] as any}
          renderItem={renderItem}
          keyExtractor={(item) => item.email as string}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    width: '100%'
  },
  inviteContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 30,
    margin: 0
  },
  headerText: {
    fontSize: 32,
    fontWeight: 700
  }
});

export default UsersTable;
