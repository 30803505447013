// User Reservation Interface
export interface IUserReservation {
  reservation_id: string;
  reservation_pwd: string;
  email: string;
  tenant_name: string;
}

export interface IReservation {
  user_id?: string;
  reservation_id: string;
  reservation_pwd: string;
  tenant_name: string;
  email: string;
}

export interface IGetUserReservation {
  reservation_id?: string;
  reservation_pwd?: string;
  email: string;
  tenant_name: string;
}

export class UserReservation implements IUserReservation {
  reservation_id: string;
  reservation_pwd: string;
  email: string;
  tenant_name: string;

  constructor(data: IUserReservation) {
    this.reservation_id = data.reservation_id;
    this.reservation_pwd = data.reservation_pwd;
    this.email = data.email;
    this.tenant_name = data.tenant_name;
  }
}
