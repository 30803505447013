import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { IUser } from '../../types/user';

interface IssueCredDetailsProps {
  user: IUser;
}

const IssueCredDetails: React.FC<IssueCredDetailsProps> = ({ user }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.sectionTitle}>CREDENTIAL DETAILS</Text>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.label}>Name:</Text>
          <Text style={styles.value}>Quartech Employee Card</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>ID:</Text>
          <Text style={styles.value}>xxxxxx-xxx-xxxx-xxxx-xxxxxxxx</Text>
        </View>
      </View>

      <Text style={styles.sectionTitle}>ISSUED TO</Text>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.label}>Username:</Text>
          <Text style={styles.value}>{user.tenant_name}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Email:</Text>
          <Text style={styles.value}>{user.email}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Wallet ID:</Text>
          <Text style={styles.value}>{user.wallet_id || 'N/A'}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center'
  },
  section: {
    marginBottom: 20
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#6c757d',
    marginBottom: 10
  },
  row: {
    flexDirection: 'row',
    marginBottom: 10
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#6c757d',
    width: 100 // Adjust width as needed
  },
  value: {
    fontSize: 14,
    color: '#000',
    flex: 1
  }
});

export default IssueCredDetails;
