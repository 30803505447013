import React, { useState } from 'react';
import GenericButton from './GenericButton';
import OnboardUserModal from '../../../modal/OnboardUserModal';
import { useTenantContext } from '../../../contexts';

interface IOnboardUserButtonProps {
  title: string;
}

const OnboardUserButton: React.FC<IOnboardUserButtonProps> = ({ title }) => {
  const tenantContext = useTenantContext();
  const [onboardUserModalOpen, setOnboardUserModalOpen] = useState(false);

  const showOnboardUserModal = () => {
    setOnboardUserModalOpen(true);
  };

  const onClose = () => {
    setOnboardUserModalOpen(false);
    tenantContext.refresh();
  };

  return (
    <>
      <OnboardUserModal isVisible={onboardUserModalOpen} onClose={onClose} />
      <GenericButton
        title={title}
        onPress={showOnboardUserModal}
        isPrimary={true}
        buttonStyle={{ width: 127, height: 41, borderRadius: 6, backgroundColor: '#1C274C' }}
        textStyle={{ fontSize: 14, fontWeight: 700 }}
      />
    </>
  );
};

export default OnboardUserButton;
