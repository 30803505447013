import React from 'react';

const BackIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3334 7.3333V8.66664H5.33344L9.0001 12.3333L8.05344 13.28L2.77344 7.99997L8.05344 2.71997L9.0001 3.66664L5.33344 7.3333H13.3334Z"
        fill="#1C274C"
      />
    </svg>
  );
};

export default BackIcon;
