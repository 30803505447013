import React, { createContext, ReactNode, useContext } from 'react';
import { AuthContextProps } from 'react-oidc-context';
import Tenant from '../types/tenant';
import { IReservation } from '../types/reservation';
import { IUser } from '../types/user';

export interface IAuthState {
  /**
   * The logged in user's Keycloak information.
   *
   * @type {AuthContextProps}
   * @memberof IAuthState
   */
  auth: AuthContextProps;
}

export const AuthStateContext = React.createContext<IAuthState | undefined>(undefined);

export interface IConfig {
  //Keycloak
  KEYCLOAK_URL: string;
  KEYCLOAK_REALM: string;
  KEYCLOAK_CLIENT_ID: string;
  WALLET_HOST: string;
}

export const ConfigContext = React.createContext<IConfig | undefined>(undefined);

export interface ITenantContext {
  tenant: Tenant | undefined;
  reservations: IReservation[] | undefined;
  users: IUser[] | undefined;
  // fetch user reservation
  fetchReservation: (email: string, reservationId: string) => Promise<IReservation | undefined>;
  // fetch admin lists
  fetchReservations: () => Promise<IReservation[] | undefined>;
  fetchTenant: () => Promise<Tenant | undefined>;
  fetchUsers: () => Promise<IUser[] | undefined>;
  fetchCredentials: (wallet_id: string) => Promise<any>;
  refresh: () => void;
}

export const TenantContext = createContext<ITenantContext>({
  tenant: undefined,
  reservations: [],
  users: [],
  fetchTenant: async () => undefined,
  fetchUsers: async () => undefined,
  fetchReservation: async () => undefined,
  fetchReservations: async () => undefined,
  fetchCredentials: async () => undefined,
  refresh: () => {}
});

export type TenantContextProviderProps = {
  children: ReactNode;
};

export interface DialogContextProps {
  sendUserInviteDialog: (reservation: IReservation) => void;
  sendUserCredDialog: (user: IUser) => void;
}

export const DialogContext = createContext<DialogContextProps | undefined>(undefined);

/**
 * Hook to access the TenantContext
 *
 * @return {*}  {ITenant}
 */
export const useTenantContext = (): ITenantContext => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
};

/**
 * Hook to access the ConfigContext
 *
 * @return {*}  {IConfig}
 */
export const useConfigContext = (): IConfig => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};

/**
 * Hook to access the AuthStateContext
 *
 * @return {*}  {IAuthState}
 */
export const useAuthStateContext = (): IAuthState => {
  const context = useContext(AuthStateContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthStateContextProvider');
  }
  return context;
};

/**
 * Hook to access the DialogContext
 *
 * @return {*}  {DialogContextProps}
 */
export const useDialogContext = (): DialogContextProps => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
