import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useAuth } from 'react-oidc-context';
import GenericButton from '../components/molecules/buttons/GenericButton';
import { useConfigContext } from '../contexts';

const HomePage: React.FC = () => {
  const auth = useAuth();
  const configContext = useConfigContext();

  return (
    <View style={styles.container}>
      <h1>Welcome to Quartech Demo Issuer Application!</h1>
      {auth.user?.profile.name && <h2>User: {auth.user?.profile.name}</h2>}
      <GenericButton
        title="Looking for Wallet? Click Here"
        onPress={() => window.open(configContext.WALLET_HOST, '_blank')}
        isPrimary
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold'
  }
});

export default HomePage;
