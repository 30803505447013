import './App.css';
import AppRouter from './routes/AppRouter';
import Layout from './components/layout/Layout';
import React from 'react';
import { DialogProvider } from './contexts/DialogContext';

function App(): React.ReactElement {
  return (
    <Layout title="Issuer Application">
      <DialogProvider>
        <AppRouter />
      </DialogProvider>
    </Layout>
  );
}

export default App;
