import React from 'react';
import { StyleSheet, Text, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';

type GenericButtonProps = {
  title: string;
  onPress: () => void;
  isPrimary: boolean;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
};

const GenericButton: React.FC<GenericButtonProps> = (props) => {
  return (
    <TouchableOpacity
      style={[props.isPrimary ? styles.primaryButton : styles.secondaryButton, props.buttonStyle]}
      onPress={props.onPress}>
      <Text
        style={[
          props.isPrimary ? styles.primaryButtonText : styles.secondaryButtonText,
          props.textStyle
        ]}>
        {props.title}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  primaryButton: {
    backgroundColor: '#1C274C',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center'
  },
  primaryButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '600'
  },
  secondaryButton: {
    backgroundColor: 'white',
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: 'black',
    alignItems: 'center',
    justifyContent: 'center'
  },
  secondaryButtonText: {
    color: 'black',
    fontSize: 16,
    fontWeight: '600'
  }
});

export default GenericButton;
