import React from 'react';
import { useAgentService } from '../services';
import { useTenantContext } from '../contexts';
import BlankModal from './BlankModal';
import IssueCredDetails from '../components/modals/IssueCredDetails';
import { IUser } from '../types/user';

export interface SendUserCredModalProps {
  isVisible: boolean;
  onClose: () => void;
  user: IUser;
}

const SendUserCredModal: React.FC<SendUserCredModalProps> = ({ isVisible, onClose, user }) => {
  const agentService = useAgentService();
  const tenantContext = useTenantContext();

  const sendCredential = async (data: IUser) => {
    await agentService.sendCredential(data);
    await tenantContext.fetchReservations();
    onClose();
  };

  return (
    <BlankModal
      title="Issue credential"
      isVisible={isVisible}
      onClose={onClose}
      onConfirm={() => sendCredential(user)}
      acceptText="Issue credential">
      <IssueCredDetails user={user} />
    </BlankModal>
  );
};

export default SendUserCredModal;
