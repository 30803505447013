import React from 'react';
import BlankModal from './BlankModal';

export interface DeleteConfirmModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  isVisible,
  onClose,
  onConfirm
}) => {
  return (
    <BlankModal
      title="Are you sure you want to delete?"
      isVisible={isVisible}
      onClose={onClose}
      onConfirm={() => onConfirm()}
      acceptText="Confirm">
      <></>
    </BlankModal>
  );
};

export default DeleteConfirmModal;
