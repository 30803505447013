import Icon from '@mdi/react';
import { Pressable, StyleSheet } from 'react-native';

type HeaderButtonProps = {
  ariaLabel?: string;
  onPress?: () => void;
  children?: React.ReactElement;
  icon: string;
};

const HeaderButton: React.FC<HeaderButtonProps> = (props: HeaderButtonProps) => {
  return (
    <Pressable
      style={styles.headerButtonStyle}
      onPress={props.onPress}
      aria-label={props.ariaLabel}>
      <Icon path={props.icon} style={styles.headerButtonIconStyle} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  headerButtonStyle: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  headerButtonIconStyle: {
    width: 24,
    color: '#000000'
  }
});

export default HeaderButton;
