import React from 'react';
import GenericButton from './GenericButton';
import { useDialogContext } from '../../../contexts';
import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { IReservation } from '../../../types/reservation';

interface IInviteUserButtonProps {
  title: string;
  reservation: IReservation;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
}

const InviteUserButton: React.FC<IInviteUserButtonProps> = ({
  title,
  reservation,
  buttonStyle,
  textStyle
}) => {
  const dialogContext = useDialogContext();

  return (
    <GenericButton
      title={title}
      onPress={() => dialogContext.sendUserInviteDialog(reservation)}
      isPrimary={false}
      buttonStyle={{ ...buttonStyle, ...styles.button }}
      textStyle={{ ...textStyle, ...styles.buttonText }}
    />
  );
};

const styles = StyleSheet.create({
  button: {
    width: 150,
    height: 41,
    borderRadius: 6
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  }
});

export default InviteUserButton;
