import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { IReservation } from '../../types/reservation';

interface UserReservationDetailsProps {
  reservation: IReservation;
}

const UserReservationDetails: React.FC<UserReservationDetailsProps> = ({ reservation }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.info}>
        User has automatically received initial Onboarding Email. Would you like to send Another?
      </Text>
      <Text style={styles.label}>
        <strong>Tenant Name:</strong> {reservation.tenant_name}
      </Text>
      <Text style={styles.label}>
        <strong>Email:</strong> {reservation.email}
      </Text>
      <Text style={styles.label}>
        <strong>User ID:</strong> {reservation.user_id}
      </Text>
      <Text style={styles.label}>
        <strong>Reservation ID:</strong> {reservation.reservation_id}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    backgroundColor: '#fff'
  },
  info: {
    fontSize: 16,
    marginBottom: 20
  },
  label: {
    fontSize: 14
  }
});

export default UserReservationDetails;
