import { View, Text, StyleSheet, Pressable, Modal } from 'react-native';
import React, { useState } from 'react';
import GenericButton from '../../../components/molecules/buttons/GenericButton';
import ReservationUserDetails from './ReservationUserDetails';
import { useDialogContext } from '../../../contexts';
import { IReservation } from '../../../types/reservation';

interface IReservationUserItemProps {
  item: IReservation;
}

/**
 * ReservationUserItem component
 *
 * @param {*} { item }
 * @return {*}
 */
const ReservationUserItem: React.FC<IReservationUserItemProps> = ({ item }) => {
  const dialogContext = useDialogContext();

  const [visible, setVisible] = useState(false);

  const onOpenDetails = () => {
    setVisible(true);
  };

  return (
    <>
      <Modal visible={visible}>
        <ReservationUserDetails item={item} setVisible={setVisible} />
      </Modal>
      <Pressable onPress={() => onOpenDetails()}>
        <View style={styles.card}>
          <View>
            <Text style={styles.name}>{item.tenant_name}</Text>
            <Text style={styles.email}>{item.email}</Text>
          </View>
          <GenericButton
            title={'Resend Invitation'}
            onPress={() => dialogContext.sendUserInviteDialog(item)}
            isPrimary={false}
            buttonStyle={styles.button}
            textStyle={styles.buttonText}
          />
        </View>
      </Pressable>
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    borderTopWidth: 1,
    borderTopColor: '#000000',
    margin: 8,
    padding: 4,
    paddingTop: 20,
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  name: {
    fontSize: 16,
    fontWeight: '700',
    color: '#000000'
  },
  email: {
    fontSize: 16,
    fontWeight: '400',
    color: '#000000'
  },
  button: {
    width: 150,
    height: 41,
    borderRadius: 6
  },
  buttonText: {
    fontSize: 14,
    fontWeight: '700'
  }
});

export default ReservationUserItem;
