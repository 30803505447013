import React from 'react';
import { TextStyle, ViewStyle } from 'react-native';
import GenericButton from './GenericButton';

export interface GenericCancelProps {
  onPress: () => void;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
}

const GenericCancel: React.FC<GenericCancelProps> = (props) => {
  return (
    <GenericButton
      title="Cancel"
      onPress={props.onPress}
      isPrimary={false}
      buttonStyle={props.buttonStyle}
      textStyle={props.textStyle}
    />
  );
};

export default GenericCancel;
